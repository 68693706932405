<template>
  <div>
    <app-header />

    <main v-if="!loading">
      <!-- slideshow content begin -->
      <div class="uk-section uk-padding-remove-vertical in-slideshow-gradient">
        <div
          id="particles-js"
          class="uk-light in-slideshow uk-background-contain"
          data-src="/theme/decor1.svg"
          data-uk-img
          data-uk-slideshow
        >
          <hr />
          <ul class="uk-slideshow-items">
            <li class="uk-flex uk-flex-middle">
              <div class="uk-container">
                <div class="uk-grid-large uk-flex-middle" data-uk-grid>
                  <div class="uk-width-1-2@s in-slide-text">
                    <h1 class="uk-heading-small">
                      {{ slider.title }}
                    </h1>
                    <div v-html="slider.tagline"></div>
                  </div>
                  <div class="in-slide-img uk-visible@m">
                    <img
                      :src="slider.background"
                      :data-src="slider.background"
                      alt="image-slide"
                      width="652"
                      height="746"
                      data-uk-img
                    />
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="uk-container">
            <div class="uk-position-relative" data-uk-grid>
              <ul
                class="
                  uk-slideshow-nav
                  uk-dotnav
                  uk-position-bottom-right
                  uk-flex
                  uk-flex-middle
                "
              ></ul>
            </div>
          </div>
        </div>
      </div>
      <!-- slideshow content end -->
      <!-- section content begin -->
      <div
        class="
          uk-section
          in-equity-11
          uk-background-contain uk-background-top-right
        "
        :data-src="how.background"
        data-uk-img
      >
        <div class="uk-container uk-margin-top uk-margin-bottom">
          <div class="uk-width-3-4@m">
            <div
              class="
                uk-grid-medium
                uk-grid-match
                uk-child-width-1-3@m
                uk-child-width-1-2@s
              "
              data-uk-grid
            >
              <div class="uk-width-1-1">
                <h1 class="uk-margin-small-bottom">{{ how.title }}</h1>
                <p class="uk-text-lead uk-margin-remove-top text-dark">
                  {{ how.tagline }}
                </p>
                <div class="uk-width-1-1">
                  <p class="uk-text-small uk-text-dark">
                    <span
                      class="
                        uk-label uk-label-info uk-margin-small-right
                        in-label-small
                      "
                      >Note</span
                    >
                    {{ how.note }}
                  </p>
                </div>
              </div>
              <div v-for="(item, i) in how.features" :key="`how-${i}`">
                <div
                  class="
                    uk-card
                    uk-card-default
                    uk-card-body
                    uk-border-rounded
                    uk-box-shadow-medium
                  "
                >
                  <div class="uk-flex uk-flex-middle">
                    <div class="uk-margin-right">
                      <img
                        :src="item.icon"
                        :data-src="item.icon"
                        alt="icon-1"
                        width="128"
                        data-height
                        data-uk-img
                      />
                    </div>
                    <div>
                      <h5 class="uk-margin-small-bottom">{{ item.title }}</h5>
                      <p class="uk-text-small uk-margin-remove">
                        {{ item.content }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->

      <!-- section content begin -->
      <div class="uk-section" style="background: #fff">
        <div class="uk-container">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-1-2@m uk-text-center">
              <h2 class="uk-text-muted uk-margin-remove-bottom uk-text-center">
                Frequently Asked Questions
              </h2>
            </div>
            <div class="uk-width-1-1@m">
              <div
                class="uk-grid uk-child-width-1-2@m uk-margin-medium-top"
                data-uk-grid=""
              >
                <div
                  class="uk-first-column"
                  v-for="(item, i) in faq"
                  :key="`faq-${i}`"
                >
                  <ul class="in-faq-2 uk-accordion" data-uk-accordion="">
                    <li class="uk-box-shadow-medium">
                      <a
                        class="uk-accordion-title"
                        href="#"
                        aria-expanded="false"
                      >
                        <div
                          class="
                            uk-card
                            uk-card-default
                            uk-card-body
                            uk-border-rounded
                          "
                        >
                          {{ item.title }}
                        </div>
                      </a>
                      <div
                        class="
                          uk-accordion-content
                          uk-card
                          uk-card-default
                          uk-card-body
                          uk-border-rounded
                        "
                        v-html="item.content"
                      ></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- section content end -->
      
      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        data-src="/new/in-equity-decor-2.svg"
        data-uk-img=""
        style="background-image: url('/new/in-equity-decor-2.svg')"
      >
        <div class="uk-container uk-margin-small-bottom">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-3-4@m uk-margin-top">
              <div
                class="
                  uk-grid
                  uk-child-width-1-3@s
                  uk-child-width-1-3@m
                  uk-text-center
                  in-register
                "
              >
                <div class="uk-width-1-1 in-h-line uk-first-column">
                  <h2>{{ getting_started.title }}</h2>
                </div>
                <div class="uk-grid-margin uk-first-column" v-for="(item, i) in getting_started.items"
                  :key="`getting-starteditem-${i}`">
                  <span class="in-icon-wrap circle">{{ i + 1 }}</span>
                  <p>{{ item }}</p>
                </div>
                <div class="uk-width-1-1 uk-grid-margin uk-first-column">
                  <router-link
                    :to="{ name: 'register' }"
                    class="uk-button uk-button-text"
                    >Open account<i
                      class="fas fa-arrow-circle-right uk-margin-small-left"
                    ></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <loading v-if="loading" />

    <app-footer :disclaimer="disclaimer" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      weeks: 0,
      sections: [],
      loading: true,
      slider: {},
      how: {},
      faq: [],
      getting_started: {},
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/academy-info").then((response) => {
        this.weeks = response.data.weeks;
        this.sections = response.data.sections;
      });

      this.loading = true;

      this.$axios.get("/api/v1/sitecontent/public-academy").then((response) => {
        this.slider = response.data.slider;
        this.how = response.data.how;
        this.faq = response.data.faq;
        this.getting_started = response.data.getting_started;
        this.disclaimer = response.data.disclaimer;
        this.loading = false;
      });
    },
  },
};
</script>
